body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.page-container {
  width: 100%;
  max-width: 960px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}
